import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Menu, X } from 'lucide-react';

const Header = ({ user, signIn, signOut }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <header className="bg-white shadow-md">
            <nav className="container mx-auto px-4 sm:px-16 py-4 flex items-center justify-between">
                {/* Logo */}
                <Link to="/" className="flex items-center space-x-2">
                    <img
                        src="https://mares.s3.amazonaws.com/output-onlinepngtools.png"
                        alt="Gaia Logo"
                        className="w-8 h-auto"
                    />
                    <span className="text-blue-600 text-xl font-semibold">Gaia</span>
                </Link>

                {/* Desktop Navigation */}
                <div className="hidden sm:flex space-x-8 items-center">
                    <Link
                        to="/documentation"
                        className="text-gray-800 font-semibold hover:text-gray-600"
                    >
                        Docs
                    </Link>
                    <Link
                        to="/models"
                        className="text-gray-800 font-semibold hover:text-gray-600"
                    >
                        Models
                    </Link>
                    {user && (
                        <Link
                            to="/dashboard"
                            className="px-6 py-2 rounded-full font-semibold bg-gray-200 text-gray-800 hover:bg-gray-300"
                        >
                            Dashboard
                        </Link>
                    )}
{/* 

                    {user ? (
                        <button
                            className="bg-blue-600 text-white hover:bg-blue-700 px-6 py-2 rounded-full font-semibold"
                            onClick={signOut}
                        >
                            Sign Out
                        </button>
                    ) : (
                        <button
                            className="bg-blue-600 text-white hover:bg-blue-700 px-6 py-2 rounded-full font-semibold"
                            onClick={signIn}
                        >
                            Sign Up
                        </button>
                    )}
*/}
                </div>

                {/* Mobile Menu Button */}
                <div className="sm:hidden">
                    <button
                        onClick={toggleMenu}
                        className="text-gray-800 hover:text-gray-600 focus:outline-none focus:text-gray-600"
                        aria-label="Toggle Menu"
                        aria-expanded={isOpen}
                    >
                        {isOpen ? <X className="w-6 h-6" /> : <Menu className="w-6 h-6" />}
                    </button>
                </div>
            </nav>

            {/* Mobile Navigation Menu */}
            {isOpen && (
                <div className="sm:hidden bg-white shadow-md">
                    <div className="px-4 pt-2 pb-4 space-y-2">
                        <Link
                            to="/documentation"
                            className="block text-gray-800 font-semibold hover:text-gray-600"
                            onClick={() => setIsOpen(false)}
                        >
                            Docs
                        </Link>
                        <Link
                            to="/models"
                            className="block text-gray-800 font-semibold hover:text-gray-600"
                            onClick={() => setIsOpen(false)}
                        >
                            Models
                        </Link>
                        {user && (
                            <Link
                                to="/dashboard"
                                className="block px-4 py-2 rounded-full font-semibold bg-gray-200 text-gray-800 hover:bg-gray-300"
                                onClick={() => setIsOpen(false)}
                            >
                                Dashboard
                            </Link>
                        )}
{/*
                        {user ? (
                            <button
                                className="w-full bg-blue-600 text-white hover:bg-blue-700 px-4 py-2 rounded-full font-semibold"
                                onClick={() => {
                                    signOut();
                                    setIsOpen(false);
                                }}
                            >
                                Sign Out
                            </button>
                        ) : (
                            <button
                                className="w-full bg-blue-600 text-white hover:bg-blue-700 px-4 py-2 rounded-full font-semibold"
                                onClick={() => {
                                    signIn();
                                    setIsOpen(false);
                                }}
                            >
                                Sign Up
                            </button>
                        )}
*/}
                    </div>
                </div>
            )}
        </header>
    );
};

export default Header;
