import React from 'react';
import { Upload, Sliders, Play, BarChart2, Key, Globe, Code, Zap } from 'lucide-react';

const EnhancedFeaturesMetrics = () => {
    const Feature = ({ icon: Icon, title, description }) => (
        <div className="flex flex-col items-center text-center p-4 bg-white rounded-lg shadow-lg transition-all duration-300 hover:shadow-xl">
            <div className="p-2 bg-blue-100 rounded-full mb-3">
                <Icon size={24} className="text-blue-600" />
            </div>
            <h3 className="text-lg font-semibold mb-2">{title}</h3>
            <p className="text-gray-600 text-sm">{description}</p>
        </div>
    );

    const MetricsCard = () => (
        <div className="bg-white rounded-lg shadow-lg p-4 transition-all duration-300 hover:shadow-xl">
            <div className="flex items-center mb-4">
                <BarChart2 size={24} className="text-blue-600 mr-3" />
                <h3 className="text-xl font-semibold">Performance Metrics</h3>
            </div>
            <div className="space-y-3">
                <div className="bg-gray-100 p-3 rounded-lg">
                    <h4 className="font-semibold mb-1 text-sm">English to French</h4>
                    <p className="text-sm">BLEU Score: 38.2</p>
                    <p className="text-sm">chrF Score: 0.65</p>
                </div>
                <div className="bg-gray-100 p-3 rounded-lg">
                    <h4 className="font-semibold mb-1 text-sm">Spanish to German</h4>
                    <p className="text-sm">BLEU Score: 35.7</p>
                    <p className="text-sm">chrF Score: 0.62</p>
                </div>
            </div>
        </div>
    );

    const ApiCard = () => (
        <div className="bg-white rounded-lg shadow-lg p-4 transition-all duration-300 hover:shadow-xl">
            <div className="flex items-center mb-4">
                <Key size={24} className="text-blue-600 mr-3" />
                <h3 className="text-xl font-semibold">API Access</h3>
            </div>
            <div className="bg-gray-100 p-3 rounded-lg">
                <pre className="text-xs overflow-x-auto">
                    <code>
                        {`import requests

API_URL = "https://gaia-cloud.io/v1/translate"
API_KEY = "your_api_key_here"

def translate(text, source_lang, target_lang):
    response = requests.post(API_URL, 
        headers={"Authorization": f"Bearer {API_KEY}"},
        json={
            "text": text,
            "source_language": source_lang,
            "target_language": target_lang
        }
    )
    return response.json()["translation"]

result = translate("Hello to everybody!", "eng_Latn", "spa_Latn")
print(result)  # ¡Hola a todos!`}
                    </code>
                </pre>
            </div>
        </div>
    );

    return (
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <section className="grid grid-cols-1 sm:py-12 sm:grid-cols-2 lg:grid-cols-3 gap-4 gap-y-16 mb-8">
                <Feature
                    icon={Upload}
                    title="Easy Data Upload"
                    description="Upload your parallel data CSV file with a simple drag-and-drop interface."
                />
                <Feature
                    icon={Sliders}
                    title="Customizable Parameters"
                    description="Fine-tune your model with advanced settings for optimal performance."
                />
                <Feature
                    icon={Play}
                    title="One-Click Training"
                    description="Start training instantly with our powerful NVIDIA GPU infrastructure."
                />
                <Feature
                    icon={Globe}
                    title="Multi-Language Support"
                    description="Train models for a wide range of language pairs, including low-resource languages."
                />
                <Feature
                    icon={Zap}
                    title="Real-Time Monitoring"
                    description="Track training progress and performance metrics in real-time."
                />
                <Feature
                    icon={Code}
                    title="Developer-Friendly"
                    description="Easily integrate your trained model with our comprehensive API."
                />
            </section>

            <section className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-8">
                <MetricsCard />
                <ApiCard />
            </section>
        </div>
    );
};

export default EnhancedFeaturesMetrics;