import React, { useEffect, useState } from 'react';
import Footer from "./footer"
import { ArrowRight, FileUp, Settings, Cpu, BarChart, Globe } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';
import EnhancedFeaturesMetrics from "./features"
import AutoDemo from "./animation"

const Home = ({ user, signIn }) => {
    const navigate = useNavigate();

    const handleGetStarted = () => {

        if (user) {
            navigate("/dashboard");
        } else {
            signIn();
        }
    };



    return(
        <div className="min-h-screen bg-gray-50">
            <main className="container mx-auto px-6 sm:px-24 py-12">
                <section className="text-center grid sm:grid-cols-2 grid-cols-1 py-16 mb-20">
                    <div className='flex items-center'>
                        <div>
                            <h1 className="text-5xl font-bold text-gray-900 mb-6">
                                Build Your Own Neural Machine Translator
                            </h1>
                            <p className="text-xl text-gray-600 mb-8 max-w-2xl mx-auto">
                                No coding required. Train, deploy, and commercialize your neural machine translator with just a few clicks.
                            </p>
                            <a href='https://forms.gle/Qvhr1Akk2xq2HaeS9' target='_blank' className='bg-blue-600 px-4 font-semibold py-2 rounded-3xl text-white hover:bg-blue-700'>
                                Join Waitlist <ArrowRight className="inline ml-2" />
                            </a>
                        </div>
                    </div>
                    <div className='flex justify-center sm:px-12'>
                        <AutoDemo />
                    </div>
                </section>
                <EnhancedFeaturesMetrics />
                <AnimatedHowItWorks />
                <section className="text-center mb-20">
                    <h2 className="text-3xl font-semibold mb-6">Ready to build your translator?</h2>
                    <a href='https://forms.gle/Qvhr1Akk2xq2HaeS9' target='_blank' className='bg-blue-600 px-4 py-2 rounded-3xl  text-white hover:bg-blue-700'>
                            Join Waitlist <ArrowRight className="inline ml-2" />
                    </a>
                </section>
            </main>
            <Footer />
        </div>
    )

};

export default Home;

const AnimatedHowItWorks = () => {
    const [activeStep, setActiveStep] = useState(0);
    const steps = [
        { icon: FileUp, text: "Upload your parallel data CSV file to the dashboard" },
        { icon: Settings, text: "Configure your model parameters and hyperparameters" },
        { icon: Cpu, text: "Click \"Start Training\" and let our GPUs do the work" },
        { icon: BarChart, text: "Review training metrics and BLEU scores" },
        { icon: Globe, text: "Use your deployed model via dashboard or API" }
    ];

    useEffect(() => {
        const timer = setInterval(() => {
            setActiveStep((prevStep) => (prevStep + 1) % steps.length);
        }, 3000);
        return () => clearInterval(timer);
    }, []);

    return (
        <section className="bg-white rounded-lg shadow-lg px-8 pt-8 pb-12 mb-20">
            <h2 className="text-3xl font-semibold text-center mb-12">How It Works</h2>
            <div className="relative">
                <div
                    className="absolute left-1/2 transform -translate-x-1/2 w-1 bg-blue-500 transition-all duration-1000 ease-in-out"
                    style={{ height: `${(activeStep + 1) * 20}%` }}
                />
                <ol className="relative z-10">
                    {steps.map((step, index) => {
                        const isActive = index === activeStep;
                        return (
                            <li key={index} className={`flex items-center mb-12 ${index % 2 === 0 ? 'flex-row-reverse' : ''}`}>
                                <div
                                    className={`flex-1 ${index % 2 === 0 ? 'text-right pr-8' : 'pl-8'} transition-all duration-300 ease-in-out`}
                                    style={{
                                        opacity: isActive ? 1 : 0.6,
                                        transform: isActive ? 'translateY(0)' : 'translateY(10px)'
                                    }}
                                >
                                    <p className={`text-lg ${isActive ? 'font-semibold' : ''}`}>{step.text}</p>
                                </div>
                                <div
                                    className={`flex-shrink-0 w-12 h-12 rounded-full flex items-center justify-center z-10 transition-all duration-300 ease-in-out
                              ${isActive ? 'bg-indigo-600' : 'bg-blue-500'}`}
                                    style={{
                                        transform: isActive ? 'scale(1.2)' : 'scale(1)'
                                    }}
                                >
                                    <step.icon size={24} className="text-white" />
                                </div>
                                <div className={`flex-1 ${index % 2 === 0 ? 'pl-8' : 'pr-8'}`}></div>
                            </li>
                        );
                    })}
                </ol>
            </div>
        </section>
    );
};

