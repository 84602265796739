import React, { useState, useEffect } from 'react';
import { Upload, Globe, ArrowRight, Play } from 'lucide-react';

const AutoDemo = () => {
    const [step, setStep] = useState(0);
    const [file, setFile] = useState(null);
    const [sourceLanguage, setSourceLanguage] = useState('');
    const [targetLanguage, setTargetLanguage] = useState('');
    const [tokenizer, setTokenizer] = useState('');
    const [batchSize, setBatchSize] = useState('');
    const [maxSequenceLength, setMaxSequenceLength] = useState('');
    const [warmupSteps, setWarmupSteps] = useState('');
    const [trainingSteps, setTrainingSteps] = useState('');
    const [learningRate, setLearningRate] = useState('');
    const [weightDecay, setWeightDecay] = useState('');
    const [isTraining, setIsTraining] = useState(false);
    const [activeField, setActiveField] = useState(null);

    const languages = ['English', 'Awajun', 'Finnish', 'Spanish', 'French', 'German', 'Italian', 'Portuguese', 'Russian', 'Chinese', 'Japanese', 'Korean', 'Arabic', 'Hindi', 'Bengali', 'Dutch', 'Turkish', 'Polish', 'Swedish', 'Vietnamese', 'Thai', 'Greek', 'Navajo'];

    const steps = [
        { action: () => { setFile('training_data.csv'); setActiveField('file'); }, delay: 1000 },
        { action: () => { setSourceLanguage('English'); setActiveField('sourceLanguage'); }, delay: 1000 },
        { action: () => { setTargetLanguage('Navajo'); setActiveField('targetLanguage'); }, delay: 1000 },
        { action: () => { setTokenizer('English'); setActiveField('tokenizer'); }, delay: 1000 },
        { action: () => { setBatchSize('16'); setActiveField('batchSize'); }, delay: 500 },
        { action: () => { setMaxSequenceLength('128'); setActiveField('maxSequenceLength'); }, delay: 500 },
        { action: () => { setWarmupSteps('1000'); setActiveField('warmupSteps'); }, delay: 500 },
        { action: () => { setTrainingSteps('32000'); setActiveField('trainingSteps'); }, delay: 500 },
        { action: () => { setLearningRate('0.0001'); setActiveField('learningRate'); }, delay: 500 },
        { action: () => { setWeightDecay('0.001'); setActiveField('weightDecay'); }, delay: 500 },
        { action: () => { setIsTraining(true); setActiveField(null); }, delay: 2000 },
        { action: () => setIsTraining(false), delay: 2000 },
        {
            action: () => {
                setFile(null);
                setSourceLanguage('');
                setTargetLanguage('');
                setTokenizer('');
                setBatchSize('');
                setMaxSequenceLength('');
                setWarmupSteps('');
                setTrainingSteps('');
                setLearningRate('');
                setWeightDecay('');
                setActiveField(null);
            }, delay: 3000
        }
    ];

    useEffect(() => {
        if (step < steps.length) {
            const timer = setTimeout(() => {
                steps[step].action();
                setStep(step + 1);
            }, steps[step].delay);
            return () => clearTimeout(timer);
        } else {
            setStep(0);
        }
    }, [step]);

    const getFieldStyle = (fieldName) => {
        return `w-full p-2 border rounded ${activeField === fieldName ? 'border-blue-500 shadow-lg' : ''} ${eval(fieldName) ? 'font-bold' : ''}`;
    };

    return (
        <div className="bg-white rounded-lg shadow-lg p-6 w-full">
            <div className="grid grid-cols-2 gap-4 mb-4">
                <div className="col-span-2">
                    <div className={`border-2 border-dashed rounded-lg p-4 text-center ${activeField === 'file' ? 'border-blue-500 shadow-lg' : 'border-gray-300'}`}>
                        {file ? (
                            <p className="text-green-600 font-bold"><Upload className="inline mr-2" size={16} />{file} uploaded</p>
                        ) : (
                            <p className="text-gray-500">Upload a CSV file (up to 10MB)</p>
                        )}
                    </div>
                </div>
                <div>
                    <select value={sourceLanguage} className={getFieldStyle('sourceLanguage')} disabled>
                        <option value="">Source Language</option>
                        {languages.map(lang => <option key={lang} value={lang}>{lang}</option>)}
                    </select>
                </div>
                <div>
                    <select value={targetLanguage} className={getFieldStyle('targetLanguage')} disabled>
                        <option value="">Target Language</option>
                        {languages.map(lang => <option key={lang} value={lang}>{lang}</option>)}
                    </select>
                </div>
                <div>
                    <select value={tokenizer} className={getFieldStyle('tokenizer')} disabled>
                        <option value="">Premade Tokenizer</option>
                        {languages.map(lang => <option key={lang} value={lang}>{lang}</option>)}
                    </select>
                </div>
                <div>
                    <input value={batchSize} placeholder="Batch Size" className={getFieldStyle('batchSize')} disabled />
                </div>
                <div>
                    <input value={maxSequenceLength} placeholder="Max Sequence Length" className={getFieldStyle('maxSequenceLength')} disabled />
                </div>
                <div>
                    <input value={warmupSteps} placeholder="Warmup Steps" className={getFieldStyle('warmupSteps')} disabled />
                </div>
                <div>
                    <input value={trainingSteps} placeholder="Training Steps" className={getFieldStyle('trainingSteps')} disabled />
                </div>
                <div>
                    <input value={learningRate} placeholder="Learning Rate" className={getFieldStyle('learningRate')} disabled />
                </div>
                <div>
                    <input value={weightDecay} placeholder="Weight Decay" className={getFieldStyle('weightDecay')} disabled />
                </div>
            </div>
            <button className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition duration-300 w-full" disabled>
                {isTraining ? (
                    <><Play className="inline mr-2 animate-pulse" size={16} /> Training in progress...</>
                ) : (
                    <>Start Training <ArrowRight className="inline ml-2" size={16} /></>
                )}
            </button>
            <div className="mt-4 text-sm text-gray-600">
                <Globe className="inline mr-2" size={16} />
                Train NLLB model on new language pairs
            </div>
        </div>
    );
};

export default AutoDemo;