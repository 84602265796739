import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useAuthState } from 'react-firebase-hooks/auth';
import { signInWithPopup } from 'firebase/auth';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { auth, googleProvider, db } from "./firebase";
import Home from "./components/home";
import Models from "./components/models";
import Docs from "./components/docs";
import Dashboard from "./components/dashboard";
import ModelDetail from "./components/modelDetail"; // New component
import Header from "./components/header";

function App() {
  const [trainingStatus, setTrainingStatus] = useState(null);
  const [user] = useAuthState(auth);
  const [models, setModels] = useState([]);
  const [publicModels, setPublicModels] = useState([]);


  useEffect(() => {
    const fetchModels = async () => {
      if (user) {
        try {
          const modelsRef = collection(db, "models");
          const q = query(modelsRef, where("uid", "==", user.uid));
          const querySnapshot = await getDocs(q);
          const modelsList = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
          setModels(modelsList);
          console.log("Models: ", modelsList);
        } catch (error) {
          console.error("Error fetching models:", error);
        }
      }
    };

    fetchModels();
  }, [user]);

  const fetchModels = async () => {
    if (user) {
      try {
        const modelsRef = collection(db, "models");
        const q = query(modelsRef, where("uid", "==", user.uid));
        const querySnapshot = await getDocs(q);
        const modelsList = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setModels(modelsList);
        console.log("Models: ", modelsList);
      } catch (error) {
        console.error("Error fetching models:", error);
      }
    }
  };

  useEffect(() => {
    const fetchPublicModels = async () => {
      try {
        const modelsRef = collection(db, "models");
        const q = query(modelsRef, where("public", "==", true));
        const querySnapshot = await getDocs(q);
        const publicModelsList = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setPublicModels(publicModelsList);
        console.log("Public Models: ", publicModelsList);
      } catch (error) {
        console.error("Error fetching public models:", error);
      }
    };

    fetchPublicModels();
  }, []);

  const signIn = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      console.log("User email signed in:", result);
    } catch (error) {
      console.error("Sign-in error:", error);
    }
  };

  const signOut = () => {
    auth.signOut();
  };

  return (
    <Router>
      <Header user={user} signIn={signIn} signOut={signOut} />
      <Routes>
        <Route path="/" element={<Home user={user} signIn={signIn} />} />
        {user && (
          <>
            <Route
              path="/dashboard"
              element={
                <Dashboard
                  user={user}
                  trainingStatus={trainingStatus}
                  setTrainingStatus={setTrainingStatus}
                  models={models}
                />
              }
            />
            <Route
              path="/dashboard/:modelId"
              element={
                <ModelDetail
                  user={user}
                  fetchModels={fetchModels}
                  models={models}
                  trainingStatus={trainingStatus}
                  setTrainingStatus={setTrainingStatus}
                />
              }
            />
          </>
        )}
        <Route path="/documentation" element={<Docs />} />
        <Route path="/models" element={<Models publicModels={publicModels} />} />
      </Routes>
    </Router>
  );
}

export default App;