import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowLeft, RotateCw } from 'lucide-react';
import axios from "axios"
import { doc, updateDoc, arrayRemove } from 'firebase/firestore';
import { Line } from 'react-chartjs-2';
import { CheckCircleIcon, AlertCircleIcon, Loader2Icon } from 'lucide-react';
import {db} from "../firebase"

const ModelDetail = ({ user, models, fetchModels, trainingStatus, setTrainingStatus }) => {
    const { modelId } = useParams();
    const [selectedModel, setSelectedModel] = useState(null);
    const [inputText, setInputText] = useState('');
    const [isLoading, setIsLoading] = useState(false)
    const [translatedText, setTranslatedText] = useState('');

    const navigate = useNavigate();

    const goDashboard = () => {
        navigate('/dashboard');
    };

    useEffect(() => {
        const model = models.find(m => m.id === modelId);
        setSelectedModel(model);
    }, [modelId, models]);

    if (!selectedModel) {
        return <div>Loading...</div>;
    }

    const handleTranslate = async () => {
        setIsLoading(true);
        console.log(inputText, selectedModel.data.sourceLang, selectedModel.data.targetLang, selectedModel.id,)
        try {
            console.log("Empezando")
            const response = await axios.post('http://inference.my-coolcloud.com/api/translate/', {
                text: inputText,
                src_lang: selectedModel.data.sourceLang,
                tgt_lang: selectedModel.data.targetLang,
                path: selectedModel.data.path,
                max_length: 128,
                num_beams: 4
            });
            console.log("resultado: ", response)
            setTranslatedText(response.data.translated_text);
        } catch (error) {
            console.error('Translation error:', error);
            setTranslatedText('Error occurred during translation');
        } finally {
            setIsLoading(false);
        }
    };

    const updateModelVisibility = async (modelId, isPublic) => {
        try {
            const modelRef = doc(db, "models", modelId);
            await updateDoc(modelRef, {
                public: isPublic
            });
            console.log("Model visibility updated successfully");
        } catch (error) {
            console.error("Error updating model visibility: ", error);
            throw error;
        }
    };

    const currentStep = selectedModel?.currentStep || 0;
    const totalSteps = selectedModel?.data.trainingSteps || 10000;
    const progressPercentage = (currentStep / totalSteps) * 100;

    const losses = selectedModel?.allLosses || [];
    const steps = losses.map((_, index) => index);

    const data = {
        labels: steps,
        datasets: [
            {
                label: 'Loss',
                data: losses,
                fill: false,
                borderColor: 'rgb(75, 192, 192)',
                tension: 0.1,
            },
        ],
    };

    const getStatusIcon = () => {
        switch (selectedModel?.trainingStatus) {
            case 'success':
                return <CheckCircleIcon className="ml-1 text-green-500" />;
            case 'error':
                return <AlertCircleIcon className="ml-1 text-red-500" />;
            default:
                return <Loader2Icon className="ml-1 animate-spin text-blue-500" />;
        }
    };

    return (
        <div className="py-8 px-16">
            {selectedModel?.trainingStatus === "success" && <>
                <div className='grid grid-cols-1 mb-6 sm:grid-cols-2'>
                    <a onClick={goDashboard} className="flex items-center my-3">
                        <ArrowLeft size={20} className="mr-3 text-blue-500 cursor-pointer" />
                        <p className="text-blue-500 cursor-pointer font-medium">Go back to dashboard</p>
                    </a>
                    <div className='flex space-x-3 items-center justify-end'>
                        <p>{selectedModel?.trainingStatus}</p>
                        <span className='font-medium'>Visibility:</span>
                        <div className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
                            <input
                                type="checkbox"
                                name="toggle"
                                id="toggle"
                                className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
                                checked={selectedModel?.public}
                                onChange={() => {
                                    const newPublicValue = !selectedModel.public;
                                    updateModelVisibility(selectedModel.id, newPublicValue)
                                        .then(() => {
                                            setSelectedModel({ ...selectedModel, public: newPublicValue });
                                        })
                                        .catch((error) => {
                                            console.error("Failed to update model visibility:", error);
                                        });
                                }}
                            />
                            <label
                                htmlFor="toggle"
                                className="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
                            ></label>
                        </div>
                        <span className="text-sm font-medium">
                            {selectedModel?.public ? <p className='text-green-500'>True</p> : <p className='text-red-500'>False</p>}
                        </span>
                    </div>
                </div>
                <div className="grid grid-cols-2 gap-4">
                    <div className="relative">
                        <h3 className="text-lg font-medium mb-2">Input ({selectedModel.data.sourceLang})</h3>
                        <textarea
                            className="w-full h-40 p-2 border outline-none rounded resize-none"
                            value={inputText}
                            onChange={(e) => setInputText(e.target.value)}
                            placeholder="Enter text to translate"
                            maxLength={500}
                            style={{ resize: 'none' }}
                        ></textarea>
                        <div className="absolute bottom-2 right-2 text-sm text-gray-500 bg-white px-1">
                            {inputText.length}/500
                        </div>
                    </div>
                    <div className="relative">
                        <h3 className="text-lg font-medium mb-2">Output ({selectedModel.data.targetLang})</h3>
                        <textarea
                            className="w-full h-40 p-2 border outline-none rounded bg-gray-100 resize-none"
                            value={translatedText}
                            readOnly
                            placeholder="Translation will appear here"
                            style={{ resize: 'none' }}
                        ></textarea>
                        <div className="absolute bottom-2 right-2 text-sm text-gray-500 bg-gray-100 px-1">
                            {translatedText.length}/500
                        </div>
                    </div>
                </div>
                <button
                    className={`mt-4 bg-blue-500 text-white px-4 py-2 rounded font-semibold hover:bg-blue-600 ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                    onClick={handleTranslate}
                    disabled={isLoading}
                >
                    {isLoading ? 'Translating...' : 'Translate'}
                </button>
            </>}

            <div className='mb-72'>
                {selectedModel?.trainingStatus !== "success" && <div className="grid grid-cols-1 mb-6 sm:grid-cols-2">
                        <div onClick={goDashboard} className="flex items-center my-3">
                            <ArrowLeft size={20} className="mr-3 text-blue-500 cursor-pointer" />
                            <p className="text-blue-500 cursor-pointer font-medium">Go back to dashboard</p>
                        </div>
                        <div className='flex space-x-3 items-center justify-end'>
                        <button className='space-x-2 text-blue-400 transition-colors duration-300  flex items-center'>
                            <a onClick={fetchModels} className=''>Update metrics</a>
                            <RotateCw onClick={fetchModels} className='w-4 h-auto ' />
                       </button> 
                        </div>
                </div>}
                <div className={`grid grid-cols-1 ${!selectedModel?.trainingStatus === "success" ? "mt-0" : "mt-12"} sm:grid-cols-2`}>
                    <div className="p-4 border rounded-md x-6 shadow-md">
                        <div className="flex items-center space-x-2 mb-4">
                            <p className="text-lg flex items-center font-semibold">Status: {selectedModel?.trainingStatus} {getStatusIcon()} </p>
                        </div>
                        <div className="mb-4">
                            <p className='pb-1'>Current step: {currentStep}/{totalSteps}</p>
                            <div className="w-full bg-gray-200 rounded-full h-4">
                                <div
                                    className="bg-blue-600 h-4 rounded-full"
                                    style={{ width: `${progressPercentage}%` }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="p-4 border rounded-md mx-6 shadow-md">
                        <h2 className="text-lg font-semibold mb-4">Loss Over Steps</h2>
                        <Line data={data} />
                    </div>
                </div>
            </div>

        </div>
    );
};

export default ModelDetail;