import React from 'react';
import { Heart, Github } from 'lucide-react';

const Footer = () => {
    const currentYear = new Date().getFullYear();

    return (
        <footer className="bg-gray-100 py-6 mt-12">
            <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex flex-col items-center justify-between sm:flex-row">
                    <p className="text-gray-600 text-sm">
                        © {currentYear} Gaia. All rights reserved.
                    </p>
                    <div className="flex items-center mt-4 sm:mt-0">
                        <a
                            href="https://github.com/yourusername/your-repo"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-gray-600 hover:text-gray-900 transition-colors duration-300"
                        >
                            <Github className="w-5 h-5 mr-2" />
                        </a>
                        <span className="text-gray-600 text-sm flex items-center">
                            Made with <Heart className="w-4 h-4 mx-1 text-red-500" /> in Peru
                        </span>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;