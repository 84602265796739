import React, { useState } from 'react';
import { ArrowLeft, Globe, ArrowRight, Copy, Volume2, Eraser, BarChart, Clock } from 'lucide-react';

const ModelCard = ({ model, onSelect }) => {
    const { sourceLang, targetLang, bleuScore } = model.data;
    const { timestamp } = model;

    const formatDate = (timestamp) => {
        if (!timestamp) return 'N/A';
        const date = timestamp.toDate();
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        return `${monthNames[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
    };

    return (
        <div className="bg-white cursor-pointer px-4 py-6 rounded-lg shadow" onClick={() => onSelect(model)}>
            <h3 className="text-lg font-semibold mb-2 flex items-center justify-between">
                {model.name}
            </h3>
            <div className="grid grid-cols-2 gap-2 text-sm">
                <div className="flex items-center">
                    <Globe size={16} className="mr-2 text-blue-500" />
                    {sourceLang || 'N/A'} → {targetLang || 'N/A'}
                </div>
                <div className="flex items-center">
                    <BarChart size={16} className="mr-2 text-blue-500" />
                    BLEU Score: {bleuScore || 'N/A'}
                </div>
                <div className="flex items-center col-span-2">
                    <Clock size={16} className="mr-2 text-blue-500" />
                    {formatDate(timestamp)}
                </div>
            </div>
        </div>
    );
};

const TranslationInterface = ({ model, onGoBack }) => {
    const [sourceText, setSourceText] = useState('');
    const [translatedText, setTranslatedText] = useState('');

    const handleTranslate = async () => {
        try {
            const response = await fetch('/api/translate', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    text: sourceText,
                    sourceLang: model.data.sourceLang,
                    targetLang: model.data.targetLang
                })
            });
            const data = await response.json();
            setTranslatedText(data.translatedText);
        } catch (error) {
            console.error('Translation error:', error);
            setTranslatedText('Error occurred during translation');
        }
    };

    const handleCopy = (text) => {
        navigator.clipboard.writeText(text);
    };

    const handleSpeak = (text, lang) => {
        const utterance = new SpeechSynthesisUtterance(text);
        utterance.lang = lang;
        window.speechSynthesis.speak(utterance);
    };

    const handleClear = (setter) => {
        setter('');
    };


    return (
        <div className="fixed inset-0 bg-gray-100 z-50 overflow-auto p-4">
            <div className="max-w-6xl mx-auto bg-white shadow-lg rounded-lg p-6">
                <button
                    onClick={onGoBack}
                    className="mb-6 flex items-center text-blue-500 hover:text-blue-600"
                >
                    <ArrowLeft size={20} className="mr-2" />
                    Back to Models
                </button>
                <h2 className="text-2xl font-bold mb-6 flex items-center">
                    <Globe className="mr-2" size={24} />
                    {model.name}
                </h2>
                <div className="flex flex-col md:flex-row gap-4 mb-6">
                    <div className="flex-1">
                        <div className="flex justify-between items-center mb-2">
                            <label className="text-sm font-medium text-gray-700">
                                {model.data.sourceLang}
                            </label>
                            <div className="flex gap-2">
                                <button onClick={() => handleSpeak(sourceText, model.data.sourceLang)} className="text-gray-500 hover:text-blue-500">
                                    <Volume2 size={18} />
                                </button>
                                <button onClick={() => handleCopy(sourceText)} className="text-gray-500 hover:text-blue-500">
                                    <Copy size={18} />
                                </button>
                                <button onClick={() => handleClear(setSourceText)} className="text-gray-500 hover:text-blue-500">
                                    <Eraser size={18} />
                                </button>
                            </div>
                        </div>
                        <textarea
                            className="w-full p-2 border outline-none border-gray-300 rounded-md h-40 resize-none"
                            value={sourceText}
                            onChange={(e) => setSourceText(e.target.value)}
                            placeholder="Enter text to translate"
                        />
                    </div>
                    <div className="flex items-center justify-center">
                        <button
                            className="p-2 bg-blue-500 text-white rounded-full hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
                            onClick={handleTranslate}
                        >
                            <ArrowRight size={24} />
                        </button>
                    </div>
                    <div className="flex-1">
                        <div className="flex justify-between items-center mb-2">
                            <label className="text-sm font-medium text-gray-700">
                                {model.data.targetLang}
                            </label>
                            <div className="flex gap-2">
                                <button onClick={() => handleSpeak(translatedText, model.data.targetLang)} className="text-gray-500 hover:text-blue-500">
                                    <Volume2 size={18} />
                                </button>
                                <button onClick={() => handleCopy(translatedText)} className="text-gray-500 hover:text-blue-500">
                                    <Copy size={18} />
                                </button>
                                <button onClick={() => handleClear(setTranslatedText)} className="text-gray-500 hover:text-blue-500">
                                    <Eraser size={18} />
                                </button>
                            </div>
                        </div>
                        <textarea
                            className="w-full p-2 border outline-none border-gray-300 rounded-md h-40 resize-none"
                            value={translatedText}
                            readOnly
                            placeholder="Translation will appear here"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

const Models = ({ publicModels }) => {
    const [selectedModel, setSelectedModel] = useState(null);

    const handleSelectModel = (model) => {
        setSelectedModel(model);
    };

    const handleGoBack = () => {
        setSelectedModel(null);
    };

    if (selectedModel) {
        return <TranslationInterface model={selectedModel} onGoBack={handleGoBack} />;
    }

    return (
        <div className="container mx-auto px-4 sm:px-20 py-8">
            <h2 className="text-2xl font-bold mb-6">Public Models</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {publicModels.map(model => (
                    <ModelCard key={model.id} model={model} onSelect={handleSelectModel} />
                ))}
            </div>
            {publicModels.length === 0 && (
                <p className="text-center py-10">No public models available at the moment.</p>
            )}
        </div>
    );
};

export default Models;