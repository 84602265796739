import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, onAuthStateChanged } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyAOYiOFVlyrhxtWYxn8J4yvCbFOdSvXYkI",
    authDomain: "gaia-b6c3d.firebaseapp.com",
    projectId: "gaia-b6c3d",
    storageBucket: "gaia-b6c3d.appspot.com",
    messagingSenderId: "143445699815",
    appId: "1:143445699815:web:58ff7938215492a1414642",
    measurementId: "G-MPTVCFN0TJ"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const googleProvider = new GoogleAuthProvider();

export { onAuthStateChanged };